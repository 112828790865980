<template>
  <div>
    <!-- 顯示資料 -->
    <div class="table table--fixed table--light">
      <!-- <div class="table-row">
        <div class="table-cell label">使用者姓名</div>
        <div class="table-cell value">{{userInfo.name}}</div>
      </div> -->
      <div class="table-row">
        <div class="table-cell label">使用者身分</div>
        <div class="table-cell value">{{userInfo.role}}</div>
      </div>
      <div class="table-row">
        <div class="table-cell label">電子信箱</div>
        <div class="table-cell value">{{userInfo.email}}</div>
      </div>
      <div class="table-row">
        <div class="table-cell label">所屬單位</div>
        <div class="table-cell value">{{userInfo.unit}}</div>
      </div>
      <div class="table-row">
        <div class="table-cell label">上次登入時間</div>
        <div class="table-cell value">{{userInfo.login_time}}</div>
      </div>
      <div class="table-row">
        <div class="table-cell label">帳戶啟用時間</div>
        <div class="table-cell value">{{userInfo.active_time}}</div>
      </div>
      <!-- <div class="table-row">
        <div class="table-cell label">帳戶註冊時間</div>
        <div class="table-cell value">{{userInfo.account_confirm_datetime}}</div>
      </div> -->
      <div class="table-row">
        <div
          class="table-cell label">
          重設密碼
        </div>
        <div class="table-cell value">
          <el-button
            type="text"
            v-if="isShowResetPasswordBlock==false"
            @click="showResetPasswordBlock">
            點此重設
          </el-button>
          <el-form
            ref="pwdForm"
            v-if="isShowResetPasswordBlock==true"
            :model="pwdForm"
            :rules="rules"
            label-position="top">
            <el-form-item label="原密碼" prop="password">
              <el-input type="password" placeholder="請輸入" v-model="pwdForm.password"></el-input>
            </el-form-item>
            <el-form-item label="新密碼" prop="new_password">
              <el-input type="password" placeholder="請輸入" v-model="pwdForm.new_password"></el-input>
            </el-form-item>
            <el-form-item label="再次輸入密碼" prop="checkPassword">
              <el-input type="password" placeholder="請輸入" v-model="pwdForm.checkPassword"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="default" @click="cancel">取消</el-button>
              <el-button type="primary" :loading="isLoading" @click="submitResetPassword">重設</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as apis from '@/apis/index.ts'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'personal',
  components: {},
  data () {
    const validatePasswordStrength = (rule, value, callback) => {
      if (value.length < 8) {
        callback(new Error('密碼必須8碼以上'))
      } else if (value.match(/([a-z])+/) == null || value.match(/([0-9])+/) == null) {
        callback(new Error('密碼必須包含英文及數字'))
      } else {
        callback()
      }
    }
    const validatePasswordCheckedStrength = (rule, value, callback) => {
      if (this.pwdForm.new_password !== this.pwdForm.checkPassword) {
        callback(new Error('密碼不一致'))
      } else {
        callback()
      }
    }
    return {
      // description: {
      //   user_name: '',
      //   role_name: '',
      //   email: '',
      //   unit_name: '',
      //   last_login_datetime: '',
      //   account_enable_datetime: '',
      //   account_confirm_datetime: ''
      // },
      isLoading: false,
      pwdForm: {
        password: '',
        new_password: '',
        checkPassword: ''
      },
      rules: {
        password: [
          { required: true, message: '必填', trigger: 'blur' }
        ],
        new_password: [
          { required: true, message: '必填', trigger: 'blur' },
          { validator: validatePasswordStrength, trigger: 'blur' }
        ],
        checkPassword: [
          { required: true, message: '必填', trigger: 'blur' },
          { validator: validatePasswordCheckedStrength, trigger: 'blur' }
        ],
      },
      isShowResetPasswordBlock: false
    }
  },
  computed: {
    ...mapState([
      'userInfo'
    ]),
  },
  methods: {
    // ...mapActions([
    //   'actionUserInfo'
    // ]),
    showResetPasswordBlock () {
      this.isShowResetPasswordBlock = true
    },
    cancel () {
      this.isShowResetPasswordBlock = false
    },
    async submitResetPassword () {
      this.$refs.pwdForm.validate(async (valid) => {
        if (!valid) {
          return
        }
        this.isLoading = true
        await apis.userPasswordReset({
          email: this.userInfo.email,
          password: this.pwdForm.password,
          new_password: this.pwdForm.new_password            
        })
        this.isLoading = false
        this.isShowResetPasswordBlock = false
      })
      
    }
  },
  created () {
    // 重新取得userInfo
    // @Q@ 目前這支api資料沒很完整，加上頁面上也沒編輯個資的功能，所以就不去更新了
    // this.actionUserInfo()
  }
}
</script>
